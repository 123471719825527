@import "./../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

//***** ANIMATIONS ******//

//Scatch

@mixin vanish() {
  -webkit-animation: fadeInFromNone 2s forwards;
  -moz-animation: fadeInFromNone 2s forwards;
  -o-animation: fadeInFromNone 2s forwards;
  animation: fadeInFromNone 2s forwards;
}

@mixin appears() {
  -webkit-animation: fadeToFront 2s forwards;
  -moz-animation: fadeToFront 2s forwards;
  -o-animation: fadeToFront 2s forwards;
  animation: fadeToFront 2s forwards;
}

@keyframes fadeInFromNone {
  0% {
    display: block;
    opacity: 1;
  }
  50% {
    display: block;
    opacity: 1;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes fadeToFront {
  0% {
    display: none;
    opacity: 0;
  }
  50% {
    display: none;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

//Casino
@keyframes hand-slide-frame {
  0% {
    top: inherit;
  }
  100% {
    top: 220px;
  }
}

@keyframes lever-slide-frame {
  0% {
    top: inherit;
    height: 150px;
    background-color: $color-machine-levier;
  }
  100% {
    top: 220px;
    height: 0px;
    background-color: darken($color-machine-levier, 30%);
  }
}

@keyframes spinItIE {
  from {
    top: -400%;
  }
  to {
    top: 0%;
  }
}
@keyframes spinIt {
  from {
    top: -200%;
  }
  to {
    top: 200%;
  }
}

//******************* //

.wheel {
  .wheel-slide {
    animation: 0.4s linear 2 alternate wheel-slide-frame;
  }
  .spinTxt {
    color: white;
    z-index: 10;
    font-size: 13px;
  }
  .spinBtnElt {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .marker-anim {
    animation: 0.05s ease-in 210 alternate marker-anim-frame;
  }

  &.mobile {
    .game-screen {
      @include mq($until: $smartphone-height, $media-feature: height) {
        height: 465px;
      }
      .game-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 88%;

        .title {
          font-size: 20px;
          width: 262px;
        }

        .game {
          width: 100%;
          row-gap: 10px;
          .title {
            margin-bottom: 0;
          }
          .handle-trigger {
            display: none;
          }
          .wheel-container {
            display: flex;
            justify-content: center;
            align-items: center;
            .spinButton {
              position: absolute;
              z-index: 10;
            }
          }
          @include mq($until: $smartphone-height, $media-feature: height) {
            background-size: 507px 465px;
          }

          .buttons-area {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  &.desktop {
    .game {
      .subtitle {
        margin-bottom: 0px;
        width: 500px;
      }
      row-gap: 30px;
      .play-button {
        margin: 5px 0 15px 0;
      }
      .handleGap {
        transform: rotate(-18deg);
      }
      .handleStick {
        position: absolute;
        left: -15px;
        top: -55px;
        z-index: 20;
        transform: rotate(-14deg);
      }
      .handle-trigger {
        position: absolute;
        width: 35px;
        height: 35px;
        top: 50px;
        right: 75px;
        transform: rotate(16deg);
        &:hover {
          cursor: pointer;
        }
      }
      .wheel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .spinButton {
          position: absolute;
          z-index: 10;
        }
      }

      @include mq(
        $from: mobile,
        $and: mq($until: m-height, $media-feature: height)
      ) {
        row-gap: 8px;

        .subtitle {
          margin-bottom: 0px;
          width: 400px;
        }
        .handleStick {
          position: absolute;
          left: -15px;
          top: -55px;
          z-index: 20;
          transform: rotate(-14deg);
        }
        .handle-trigger {
          position: absolute;
          width: 35px;
          height: 35px;
          top: 70px;
          right: 120px;
          transform: rotate(7deg);
          &:hover {
            cursor: pointer;
          }
        }
        .wheel-container {
          display: flex;
          justify-content: center;
          align-items: center;
          .spinButton {
            position: absolute;
            z-index: 10;
          }
        }
      }

      @include mq(
        $from: mobile,
        $and: mq($until: s-height, $media-feature: height)
      ) {
        .subtitle {
          font-size: 18px;
        }
        .handleStick {
          position: absolute;
          left: -0px;
          top: -30px;
          z-index: 20;
          transform: rotate(-14deg);
        }
        .handle-trigger {
          position: absolute;
          width: 35px;
          height: 35px;
          top: 100px;
          right: 210px;
          transform: rotate(0deg);
          &:hover {
            cursor: pointer;
          }
        }

        .game-screen {
          background-size: 500px 370px;

          .game-screen-content {
            row-gap: 10px;
          }
        }
        .wheel-container {
          display: flex;
          justify-content: center;
          align-items: center;
          .spinButton {
            position: absolute;
            z-index: 10;
          }
        }
      }

      @include mq(
        $from: mobile,
        $and: mq($until: xs-height, $media-feature: height)
      ) {
        .game-screen {
          background-size: 250px auto;
          .game-screen-content {
            row-gap: 0;
          }
        }
      }
    }
  }
}
.flip {
  &.mobile {
    .game {
      row-gap: 30px !important;
      & > .title {
        position: relative;
        font-size: 30px;
      }
      .subtitle {
        width: 70%;
        margin-left: 15%;
      }
      .buttons-area {
        .title {
          margin-bottom: 0 !important;
          width: 160px;
          font-size: 16px;
          line-height: normal;
        }
      }
    }
    .card-style {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100vw;
      gap: 10px;
    }
    .card {
      width: 29%;
      height: 160px;
    }
  }
  &.desktop {
    .game {
      row-gap: 30px;
    }
    .card-style {
      max-width: 600px;
      display: flex;
      flex-direction: row;
      gap: 0px 30px;
      justify-content: center;
      align-items: center;
    }
    .card {
      width: 140px;
      height: 200px;
    }
    .img-center-card {
      width: calc(140px / 1.4);
      height: calc(140px / 1.4);
    }

    @include mq(
      $from: mobile,
      $and: mq($until: m-height, $media-feature: height)
    ) {
      .card-style {
        max-width: 380px;
        gap: 0px 25px;
      }
      .card {
        width: 120px;
        height: 160px;
      }
      .img-center-card {
        width: calc(120px / 1.4);
        height: calc(120px / 1.4);
      }
    }

    @include mq(
      $from: mobile,
      $and: mq($until: s-height, $media-feature: height)
    ) {
      .game {
        row-gap: 30px;
      }
      .card-style {
        max-width: 280px;
        gap: 0px 20px;
      }
      .card {
        width: 80px;
        height: 120px;
      }
      .img-center-card {
        width: calc(80px / 1.4);
        height: calc(80px / 1.4);
      }
    }
  }
}
.casino {
  .lever-slide {
    animation: 0.4s linear 2 alternate lever-slide-frame;
  }
  .hand-slide {
    animation: 0.4s linear 2 alternate hand-slide-frame;
  }
  .screen-container {
    z-index: 2;
  }

  &.mobile {
    .game-screen {
      @include mq($until: $smartphone-height, $media-feature: height) {
        height: 465px;
      }
      .game-content {
        display: flex;
        align-items: center;
        height: 100%;

        .game {
          width: 100%;
          background-size: 507px 530px;
          row-gap: 25px;
          @include mq($until: $smartphone-height, $media-feature: height) {
            background-size: 507px 465px;
          }

          .odds-text {
            color: rgb(245, 225, 75);
          }

          .screen-container {
            width: 380px;
            height: 160px;
            border-radius: 150px;
            border: solid 12px $color-machine-border;
            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.7);
            display: flex;
            overflow: hidden;
            @include mq($until: $smartphone-height, $media-feature: height) {
              width: 320px;
            }
          }

          .screen-column {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 34%;
            background-color: white;
            border-left: solid 5px $color-machine-border;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img {
              width: 60px;
              position: relative;
              margin: 9px;
              user-select: none;
              top: 30%;
              width: 50%;
              //IE
              @media screen and (-ms-high-contrast: active),
                screen and (-ms-high-contrast: none) {
                top: -480%;
              }
            }
            .winnerImg {
              top: 0;
              @media screen and (-ms-high-contrast: active),
                screen and (-ms-high-contrast: none) {
                top: -30%;
              }
            }
          }
          .screen-column-first {
            border-left: none;
          }

          .buttons-area {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .insert-coin {
              display: none;
            }
          }
        }

        .slot-lever,
        .slot-lever-support,
        .slot-hand {
          display: none;
        }
      }
    }
  }

  &.desktop {
    .game-content {
      position: relative;
      margin: auto;
      display: flex;
      .game {
        row-gap: 20px;
        .title {
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.03em;
          max-width: inherit;
          position: relative;
        }
        .subtitle {
          display: flex;
          margin-block: 0;
        }
        .odds-text {
          color: rgb(245, 225, 75);
        }

        .description {
          display: flex;
          flex-direction: column;
          padding: 0 0 5px;
          font-size: 17px;

          .or {
            opacity: 0.5;
          }
        }

        .screen-container {
          width: 550px;
          height: 200px;
          border-radius: 150px;
          border: solid 12px $color-machine-border;
          box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.7);
          display: flex;
          overflow: hidden;

          .screen-column {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 34%;
            background-color: white;
            border-left: solid 5px $color-machine-border;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            img {
              position: relative;
              margin: 9px;
              user-select: none;
              top: 30%;
              width: 50%;
              //IE
              @media screen and (-ms-high-contrast: active),
                screen and (-ms-high-contrast: none) {
                top: -480%;
              }
            }
            .winnerImg {
              top: 0;
              @media screen and (-ms-high-contrast: active),
                screen and (-ms-high-contrast: none) {
                top: -30%;
              }
            }
          }

          .screen-column-first {
            border-left: none;
          }
        }
      }
      .lever {
        position: absolute;
        left: 540px;
        bottom: 160px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 60px;
        text-align: center;
        cursor: pointer;
        z-index: 1;

        .slot-hand {
          width: 52px;
          height: 52px;
          background-color: $color-machine-head;
          border-radius: 35%;
          align-self: center;
        }

        .slot-lever {
          width: 13px;
          height: 150px;
          background-color: $color-machine-levier;
          align-self: center;
        }
        .slot-lever-support {
          width: 60px;
          height: 60px;
          background: $color-machine-levier;
          border-radius: 15px;
          background-size: 85px 80px;
        }
      }

      .insert-coin {
        position: absolute;
        right: 100px;
        bottom: 100px;
        display: flex;
        margin-left: 4%;
        margin-top: 1%;
        width: 96px;
        height: 37px;
        background-color: $insert-coin-color;
        border-radius: 9px;

        .coin-line {
          margin: auto;
          justify-items: center;
          align-items: center;
          width: 44px;
          height: 0px;
          border-radius: 9px;
          border: 4px solid rgba(0, 0, 0, 0.4);
        }
      }
    }

    @include mq(
      $from: mobile,
      $and: mq($until: m-height, $media-feature: height)
    ) {
      .game-content {
        .game {
          background-size: 550px auto;
          row-gap: 15px;

          .title {
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.03em;
            max-width: inherit;
          }

          .screen-container {
            width: 490px;
            height: 170px;
            border-radius: 80px;
            .screen-column {
              img {
                //IE
                @media screen and (-ms-high-contrast: active),
                  screen and (-ms-high-contrast: none) {
                  top: -480%;
                }
              }
              .winnerImg {
                top: 0;
                @media screen and (-ms-high-contrast: active),
                  screen and (-ms-high-contrast: none) {
                  top: -50%;
                }
              }
            }
          }

          .slot-lever-support {
            top: 307px;
            left: 637px;
            background-size: 65px;
          }

          .slot-lever {
            top: 27%;
            left: 666px;
            width: 10px;
            height: 175px;
          }

          .slot-hand {
            top: 26.5%;
            left: 651px;
            width: 40px;
            height: 40px;
          }

          .buttons-area {
            .insert-coin {
              position: absolute;
              right: 180px;
              bottom: 140px;
              width: 50px;
              height: 10px;
              width: 50px;
              height: 25px;
              border-radius: 6px;

              .coin-line {
                width: 30px;
                border-radius: 6px;
                border: 3px solid rgba(0, 0, 0, 0.4);
              }
            }
          }
        }
      }
    }

    @include mq(
      $from: mobile,
      $and: mq($until: s-height, $media-feature: height)
    ) {
      .game-content {
        .game {
          .title {
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.03em;
            max-width: inherit;
          }

          .screen-container {
            width: 300px;
            height: 110px;
            .screen-column {
              img {
                //IE
                @media screen and (-ms-high-contrast: active),
                  screen and (-ms-high-contrast: none) {
                  top: -480%;
                  width: 50px;
                }
              }
              .winnerImg {
                top: 0;
                @media screen and (-ms-high-contrast: active),
                  screen and (-ms-high-contrast: none) {
                  top: 0;
                }
              }
            }
          }
        }

        .slot-lever-support {
          top: 270px;
          left: 540px;
          background-size: 52px;
        }

        .slot-lever {
          top: 37%;
          left: 580px;
          width: 7px;
          height: 100px;
        }
        .slot-hand {
          top: 31%;
          left: 566px;
          width: 35px;
          height: 35px;
        }

        .buttons-area {
          // margin-left: 7% !important;

          .insert-coin {
            position: absolute;
            right: 100px;
            bottom: 100px;
            width: 50px;
            height: 10px;
            width: 50px;
            height: 25px;
            border-radius: 6px;

            .coin-line {
              width: 30px;
              border-radius: 6px;
              border: 3px solid rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
    }

    @include mq(
      $from: mobile,
      $and: mq($until: xs-height, $media-feature: height)
    ) {
      .game-content {
        .game {
          min-height: 200px;
          background-size: 230px auto;
          row-gap: 0;

          .title {
            font-size: 10px;
          }

          .screen-container {
            width: 200px;
            height: 90px;
          }
        }

        .slot-lever-support {
          top: 95px;
          left: 458px;
          background-size: 40px;
        }

        .slot-lever {
          top: 25%;
          left: 500px;
          width: 5px;
          height: 80px;
        }

        .slot-hand {
          left: 490px;
          width: 25px;
          height: 25px;
        }

        .buttons-area {
          .insert-coin {
            width: 50px;
            height: 25px;
            border-radius: 6px;

            .coin-line {
              width: 30px;
              border-radius: 6px;
              border: 3px solid rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
    }
  }
}
.scratch {
  .video-scratch {
    clip-path: inset(1px 1px);
  }
  .auto-scratch-back {
    position: absolute;
    top: 101px;
    left: 85px;
  }
  .auto-scratch-result {
    position: absolute;
    top: 50%;
    left: 50%;
    // when winnning
    transform: translate(-50%, -50%) rotate(-10deg);
    // when lost
    &.lose {
      transform: translate(-60%, -70%) rotate(-10deg);
    }
  }

  .screen-container-scratch {
    &.animation {
      canvas:nth-child(2) {
        display: none;
      }
      canvas:nth-child(1),
      canvas:nth-child(3) {
        @include vanish;
      }
      .auto-scratch-txt {
        @include appears();
      }
    }
  }

  &.mobile {
    .game-content {
      // width: 100%;
      .title {
        font-size: 28px;
        width: 80%;
      }
      .game {
        position: relative;
        top: 20px;
        width: 100%;
        height: 100%;
      }
      .scratch-layers {
        position: relative;
        height: 200px;
        width: 100%;
      }
      .scratch-here-img {
        position: absolute;
        left: auto;
        right: 10px;
        bottom: -8%;
        pointer-events: none;
        width: 40%;
      }
      .screen-container-scratch {
        margin-top: 12px;
        & > div {
          margin: 0 auto;
          canvas:nth-child(1),
          canvas:nth-child(3) {
            position: absolute;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            margin-top: 2px;
            margin-left: 8px;
          }
        }
        div > canvas:nth-child(2) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-10deg);
        }
        &.lose div > canvas:nth-child(2) {
          transform: translate(-50%, -60%);
        }
        .auto-scratch-txt {
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 18px;
          rotate: -10deg;
          transform: translate(-50%, -50%);
          margin-top: 30px;
          margin-left: 10px;
        }
        // we dont need a front, just vanish the canvas
        .auto-scratch-front {
          display: none;
        }
        .auto-scratch-back {
          position: absolute;
          top: 50% !important;
          left: 50% !important;
          transform: translate(-50%, -50%) !important;
        }
      }
    }
  }
  &.desktop {
    .scratch-layers {
      height: 350px;
      width: 600px;
    }
    .scratch-here-img {
      position: absolute;
      right: 30px;
      left: auto;
      bottom: 60px;
      pointer-events: none;
    }
    .screen-container-scratch {
      position: absolute;
      width: auto;
      height: auto;
      min-height: 352px !important;
      width: 100%;
      & > div {
        margin: 0 auto;
        min-height: 352px !important;
        padding-top: 0 !important;
        canvas:nth-child(1),
        canvas:nth-child(3) {
          position: absolute;
          top: 50% !important;
          left: 50% !important;
          transform: translate(-50%, -50%) !important;
          margin-top: -1px;
          margin-left: 1px;
        }
      }
      div > canvas:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-10deg) !important;
        margin-top: -5px;
        margin-left: -12px;
      }
      &.lose div > canvas:nth-child(2) {
        transform: translate(-50%, -50%) !important;
        margin-top: -30px;
        margin-left: -22px;
      }
    }
    .auto-scratch-front {
      display: none;
    }
    .auto-scratch-back {
      position: absolute;
      top: 60% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      margin-left: -13px;
      margin-top: -3px;
    }
    .auto-scratch-txt {
      opacity: 0;
      position: absolute;
      font-size: 30px;
      rotate: -10deg;
      top: 50%;
      left: 50%;
      margin-left: 4px;
      transform: translate(-50%, -50%);
    }
    .game {
      @include mq(
        $from: mobile,
        $and: mq($until: m-height, $media-feature: height)
      ) {
        .buttons-area {
          margin-top: -40px;
        }
      }
      @include mq(
        $from: mobile,
        $and: mq($until: s-height, $media-feature: height)
      ) {
        .buttons-area {
          margin-top: -20px;
        }
      }
    }
  }
}
