@import "./../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.desktop {
  .checkbox-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.mobile {
  .checkbox-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.agree {
  font-family: arial;
  padding-top: 3px;
  .checkbox {
    border-color: $color-secondary;
    svg {
      fill: $color-secondary;
    }
  }
}
.info-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 18px;
}
