@import "./../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  position: absolute;
  top: 4%;
  z-index: 100000;
}

.desktop .logo img {
  max-height: 80px;
  width: auto;
}

@include mq($until: s-height, $media-feature: height) {
  .logo {
    top: 15px;
    text-align: center;
    position: absolute;

    img {
      width: 100px;
    }
  }
}

@include mq($until: mobile) {
  .logo {
    padding-top: 26px;
    text-align: center;
    position: initial;
    img {
      max-width: 250px;
      max-height: 70px;
    }
    @include mq($until: $smartphone-height, $media-feature: height) {
      margin-top: 14px;
    }
  }
}
