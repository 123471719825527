@import "./../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.display-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.mobile {
  .display-screen {
    margin-top: 160px;
  }

  .game {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 10;
    background-size: 719.6px 530px;
  }
}
.desktop {
  .display-screen {
    height: 100vh;
  }

  .background {
    z-index: 20;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    margin: auto;
  }
  .game {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
  }
}

.game-screen {
  display: flex;
  font-family: "Righteous";
  padding: 40px 20px;
  border-radius: 20px;
  .txt-date {
    color: $color-secondary;
  }

  .game-screen-content {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    row-gap: 25px;
    form {
      row-gap: 30px;
    }
  }
}

.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
