.button-area {
  display: flex;
  gap: 10px;
  margin: 0 auto;
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
.mobile {
  .button-area {
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
    gap: 0px;
  }
}
