.checkbox {
  width: 15px;
  height: 15px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  cursor: pointer;
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    margin-right: 10px;
  }
}
.checked {
  border: 2px solid #ffffff;
  display: flex;
  justify-items: center;
  align-items: center;
  img {
    margin: auto;
    position: relative;
    user-select: none;
  }
}
