@import "./../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.replay-container {
  z-index: 20000;
}

.modal-replay {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 40px 72px;
  overflow: hidden;
  align-self: center;
  max-width: 40%;
  z-index: 20000;
}
.title-modal {
  margin-top: 40px;
  font-family: Righteous;
  font-weight: normal;
  font-size: 25px;
  margin-bottom: 0;
}
.subtitle-modal {
  font-family: Righteous;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

p {
  font-size: 14px;
}

.button-play-modal {
  background-color: #2e70de;
  border-radius: 100px;
  color: white;
  font-family: Righteous;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  padding: 15px 34px;
  margin-top: 25px;
  cursor: pointer;
}
.button-close-modal {
  position: absolute;
  top: 25px;
  right: 25px;
  color: black;
  cursor: pointer;
}

.img-modal {
  width: 100%;
}

.ReactModal__Overlay {
  z-index: 99;
  background-color: #1e1717b3 !important;
  display: flex;
  justify-content: center;
  position: relative;
}

.loader-style {
  top: 200px;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}

.modal-chance {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 20px 40px;
  overflow: hidden;
  position: absolute;
  align-self: center;
  max-width: 30%;
  z-index: 20000;
  .title-chance {
    color: black;
    font-family: Righteous;
    font-weight: normal;
    font-size: 25px;
    margin-block: 0;
  }
  .play-chance {
    background-color: rgb(53, 60, 104);
    border-radius: 10px;
    color: white;
    font-family: Righteous;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    padding: 10px 70px;
    margin-top: 25px;
    cursor: pointer;
  }
  .luck-content {
    background-color: rgb(40, 112, 24);
    width: 120px;
    height: 120px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    .subtitle-modal {
      color: rgb(240, 220, 48);
      width: 100px;
      font-size: 20px;
    }
  }
  .clover {
    position: absolute;
    right: 27%;
    top: 38%;
  }
  p {
    font-weight: 800;
  }
}
@include mq($until: mobile) {
  .modal-replay,
  .modal-chance {
    max-width: 75%;
    padding: 20px;
    .title-modal,
    .title-chance {
      font-size: 25px;
      margin-top: 10px;
    }
    .subtitle-modal {
      font-size: 16px;
    }
    p {
      font-size: 13px;
    }
  }
}
