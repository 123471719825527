@import "./../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.collect-info-container {
  .container-line-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    input {
      width: 95%;
      display: flex;
      justify-content: flex-start;
    }
  }
}

.mobile {
  .collect-info-container {
    margin-top: 15px;
    .subtitle {
      margin-bottom: 10px;
    }
    form {
      row-gap: 20px !important;
      .container-line-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        width: 90vw;
        input {
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }
}
