@import "../../../../../shop//assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.progress-bar {
  // width: 100%;
  width: 150px;
  margin-top: 30px;
  color: green;
  & > div {
    margin: 0 auto;
  }
}

.inputFile {
  margin-bottom: 10px;
  .uploadFile {
    cursor: pointer;
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin-top: 16px;
    }
    display: flex;
    flex-direction: row;
    gap: 16px;

    .uploadImg {
      border: 2px solid $color-secondary;
      border-radius: 54px;
      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        margin-right: 5px;
      }
    }

    .uploadLabel {
      color: $color-secondary;
      left: 25.55%;
      right: 0%;
      top: 24.07%;
      bottom: 25.93%;
      font-family: Righteous;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;
    }
  }

  .uploadedFileText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: $color-secondary;
    .deleteImg {
      cursor: pointer;
    }
  }
}

@include mq($from: mobile, $and: mq($until: s-height, $media-feature: height)) {
  .stepper-inputs {
    grid-row-gap: 10px;
    row-gap: 10px;
    margin-bottom: 10px;
    form {
      .inputFile .uploadFile {
        gap: 10px;

        .uploadImg {
          padding: 5px;
          width: 30px;
          height: 30px;
        }

        .uploadLabel {
          font-size: 13px;
        }
      }
      .uploadedFileText {
        font-size: 13px;
      }
    }
  }
}

.button {
  margin-top: 29px;
}
