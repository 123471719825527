.confetti {
  position: fixed;
  top: 0%;
  left: 50%;
}

.win-content {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  align-items: center;
}
