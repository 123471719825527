$color-primary: #ed5b2d;
$color-light-primary: #fcf3e2;
$color-secondary: black;
$color-grey: #c4c4c4;
$color-info: black;
$cta-background-color: #e2001a;
$cta-text-color: #ffffff;
$input-error-color: rgba(0, 0, 0, 0.6);
$input-info-color: #58585a;
$footer-text-color: #58585a;
$insert-coin-color: rgba(0, 0, 0, 0.2);
//SLOT MACHINE
$color-machine-levier: #ccb600;
$color-machine-head: #1b66b3;
$color-machine-border: #636155;
//WEB RESPONSIVE
$xs-height: 330px;
$s-height: 565px;
$m-height: 745px;

$mobile-landscape: 639px;
$smartphone-height: 641px;
$iphone-x-height: 823px;
$smartphone-width: 414px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;

:export {
  //Wheel
  primary: $color-primary;
  lightPrimary: $color-light-primary;
  innerLine: #9b6c05;
  outerRadious: #ff0003;
}

// Breakpoint
$mq-breakpoints: (
  mobile-sm: 350px,
  mobile: $mobile-landscape,
  tablet-portrait: $tablet-portrait,
  tablet: $tablet-landscape,
  desktop-sm: 1366px,
  desktop: 1440px,
  desktop-large: 1600px,
  desktop-xlarge: 1681px,
  mobileLandscape: $mobile-landscape,
  xs-height: $xs-height,
  s-height: $s-height,
  m-height: $m-height,
);

/*
$mq-show-breakpoints: (
  mobile-sm,
  mobile,
  tablet-portrait,
  tablet,
  desktop-sm,
  desktop,
  desktop-large,
  desktop-xlarge,
  mobileLandscape,
  xs-height,
  s-height,
  m-height
);*/
