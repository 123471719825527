@import "./../../../../shop/assets/styles/variables.scss";

form {
  font-family: "Open sans";
  align-items: center;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;

  input[type="text"] {
    padding: 0px 10px 10px 0;
    background-color: transparent;
    border: none;
    color: $color-secondary;
    border-bottom: 2px solid transparentize($color-secondary, 0.7);
    outline: none;
    font-size: 18px;
    width: 378.83px;
    text-align: center;
  }

  .top-placeholder {
    width: 100%;
    min-height: 19px;
    margin-bottom: 3px;
  }
  .text-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
  }

  input[type="text"]:focus {
    border-bottom: 2px solid $color-secondary;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    border-bottom: 2px solid $color-secondary;
    -webkit-text-fill-color: $color-secondary;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input::-webkit-input-placeholder,
  .top-placeholder {
    color: $color-secondary;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    opacity: 0.6;
    text-align: left;
  }

  .error-message {
    color: $input-error-color;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    width: 378.83px;
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $input-info-color;
    text-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    max-width: 500px;
  }

  .info {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $color-info;
    width: 50%;
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      max-width: 60%;
    }
  }

  .loading-container {
    position: relative;
  }
}

@include mq($from: mobile, $and: mq($until: s-height, $media-feature: height)) {
  form {
    input[type="text"] {
      padding: inherit;
      padding-bottom: 5px;
      max-width: 230px;
      font-size: 11px;
    }

    .top-placeholder {
      width: 100%;
    }

    input::-webkit-input-placeholder {
      font-size: 9px;
    }

    .error-message {
      max-width: 230px;
    }

    .input-error-message {
      font-size: 7px;

      img {
        bottom: 17px;
      }
    }

    .info,
    label {
      font-size: 9px;
      max-width: 270px;
      margin: 0;
    }
  }
}

@include mq(
  $from: mobile,
  $and: mq($until: xs-height, $media-feature: height)
) {
  form {
    input[type="text"] {
      padding: inherit;
      padding-bottom: 5px;
      max-width: 170px;
      font-size: 9px;
    }

    .top-placeholder {
      width: 100%;
    }

    input::-webkit-input-placeholder,
    .top-placeholder {
      font-size: 9px;
    }

    .error-message {
      max-width: 170px;
    }

    .input-error-message {
      font-size: 5px;

      img {
        width: 10px;
        bottom: 17px;
      }
    }

    .info,
    label {
      font-size: 5px;
      max-width: 170px;
      margin: 0;
    }
  }
}

@include mq($until: mobile) {
  form {
    input[type="text"],
    .top-placeholder,
    .error-message {
      max-width: 325.99px;
    }
  }
}
