@import "../../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.legal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;

  color: #000000;
  .legal-content {
    display: flex;
    background-color: white;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5%;
    padding: 120px 107px;
    z-index: 10000;
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 30px;
      letter-spacing: 0.03em;
    }
    .back-button {
      background: url("../../../../../shop/assets/img/back.png") no-repeat
        center;
      width: 37px;
      height: 23px;
      margin-bottom: 5%;
      position: absolute;
      top: 50px;
      left: 250px;
    }
    .back-button-mobile {
      background: url("../../../../../shop/assets/img/back.png") no-repeat
        center;
      width: 37px;
      height: 23px;
      margin-bottom: 5%;
      position: absolute;
      top: 30px;
      left: 20px;
    }
    h1 {
      line-height: normal;
    }
    .legal-text {
      & > div {
        a {
          text-decoration: none;
        }
        & > div {
          padding: 5px 0;
        }
      }
    }
  }
}
@include mq($until: tablet-portrait) {
  .legal-container {
    font-size: 12px;
    .legal-content {
      width: 100%;
      padding: 10px 30px;
      h2 {
        font-size: 22px;
      }
    }
  }
}
