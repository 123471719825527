* {
  -ms-overflow-style: none;
  box-sizing: border-box;
  white-space: wrap;
  align-content: center;
}
body {
  margin: 0;
  padding: 0;
  white-space: wrap;
  box-sizing: content-box;
  overflow-x: hidden;
  width: 100.2vw;
}

::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0px;
  background: transparent;
}

.mobile {
  .app-container {
    & > img {
      // dont display packshot when none of the clients
      // ms_ecom, ms_pin, ms-receipt
      display: none;
    }
  }
  overflow: auto;
  height: 100%;
}

#root {
  .mobile {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    .app-container {
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
        background: transparent;
      }
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .logo {
        padding-top: 30px;
      }
      & > a {
        padding: 3px;
        height: 40%;
        width: 100vw !important;
        img {
          padding-top: 0 !important;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      & > img {
        padding: 8px;
        width: 100vw !important;
        height: 60%;
        display: block;
        object-fit: contain;
      }
    }
  }
}

body {
  margin: 0;
  flex: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

// @media screen and (max-width: 768px) {
//   ::-webkit-scrollbar {
//     -webkit-appearance: none;

//     width: 0px;
//     background: transparent;
//   }
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Righteous";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Righteous-Regular.ttf");
}
@font-face {
  font-family: "Open sans";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/OpenSans-Regular.ttf");
}
body {
  font-family: "Open sans";
}
