@import "./../../../../shop/assets/styles/variables.scss";
@import "~@mcaskill/sass-mq";

.layout-teasing {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-attachment: fixed;
  background-size: contain;
  .layout-content {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    border-radius: 80px;
    width: 60%;
    .container-rule-button {
      width: 430px;
      background: url("../../../resources/img/deco.png") no-repeat;
      background-position: center right;
      background-size: 270px 160px;
      margin-inline: calc(50% - 170px);
    }
    .layout-video {
      background: url("../../../resources/img/ellipse.png") no-repeat;
      background-position: bottom 0px right 90px;
      background-size: 200px;
      display: flex;
      flex-direction: column;
      justify-content: top;
      align-items: center;
      align-self: center;
      height: 630px;
      width: 700px;
      .video {
        border-radius: 20px;
        border: none;
      }

      .title-video {
        font-family: Righteous;
        font-size: 35px;
        font-weight: 100;
        margin-inline: auto;
      }
    }

    .layout-faq {
      display: flex;
      flex-direction: column;

      .deco2 {
        margin-top: 30px;
        position: relative;
        left: calc(50% - 160px);
      }
      .title-faq {
        margin-inline: auto;
        margin-top: -20px;
        font-size: 35px;
        font-family: Righteous;
        font-weight: 100;
      }
      .faq-column {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        flex: 0 0 auto;
        padding-inline: 5%;
        .left-column,
        .right-column {
          display: flex;
          flex-direction: column;
          width: 45%;
        }
        .card-faq {
          display: flex;
          margin-bottom: 20px;
          flex-direction: column;
          justify-content: flex-start;
          border-radius: 8px;
          border: none;
          background-color: rgba(255, 255, 255, 0.8);
          box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);

          .quest-faq {
            font-family: Righteous;
            font-weight: 100;
            border-radius: 8px 8px 0 0;
            background-color: #2e70de;
            color: white;
            display: flex;
            padding: 16px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
          }
          .answer-faq {
            font-family: Arial;
            color: black;
            padding: 10px;
            // height: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }
  .share-layout {
    width: 50%;
    align-self: center;
    margin-block: 80px;
  }

  @include mq($until: mobile) {
    .layout-content {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      border-radius: 80px;
      width: 100%;
      .container-rule-button {
        width: auto;
        background: url("../../../resources/img/deco-mobile.png") no-repeat;
        background-position: top -5px right 0px;
        background-size: 180px 200px;
        margin-inline: calc(50% - 170px);
        margin-top: 80px;
      }
      .layout-video {
        background: url("../../../resources/img/ellipse.png") no-repeat;
        background-position: bottom 0px right 90px;
        background-size: 200px;
        display: flex;
        flex-direction: column;
        justify-content: top;
        align-items: center;
        align-self: center;
        height: 630px;
        width: 700px;
        .video {
          border-radius: 50px;
        }

        .title-video {
          font-family: Righteous;
          font-size: 35px;
          font-weight: 100;
          margin-inline: auto;
        }
      }
      .layout-faq {
        display: flex;
        flex-direction: column;
        padding-inline: 20px;

        .deco2 {
          margin-top: 30px;
          position: relative;
          left: calc(50% - 160px);
        }
        .title-faq {
          align-self: center;
          margin-top: -20px;
          font-size: 35px;
          font-family: Righteous;
          font-weight: 100;
        }
        // display: flex;
        // flex-direction: column;
        flex: 1;
        .faq-column {
          display: flex;
          flex-direction: column;
          .left-column,
          .right-column {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
          .card-faq {
            display: flex;
            margin-bottom: 20px;
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 8px;
            width: 100%;
            border: 1px none solid;
            box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.8);

            .quest-faq {
              font-family: Righteous;
              font-weight: 100;
              border-radius: 8px 8px 0 0;
              background-color: #2e70de;
              color: white;
              display: flex;
              padding: 16px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            }
            .answer-faq {
              font-family: Arial;
              color: black;
              padding: 10px;
              height: 100%;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
